import React from 'react';
import Helmet from 'react-helmet';
import { StyledWrapper, PreviewBlock } from '../../../components/styles/block.style';
import { Layout } from '../../../components/DarkLayout/Layout';
import image from '../../../components/images/thumbnailform.jpg';
import { Cta } from '../../../components/FormBuilder/Cta';

const Block = () => {
  return (
    <Layout>
      <Helmet>
        <title>
          Windframe Form builder - Visual form builder for rapidly building stunning forms and form
          layouts using tailwind css
        </title>
        <meta
          name="title"
          content="Windframe Form builder -  Visual form builder for rapidly building stunning forms and form layouts using tailwind css"
        />
        <meta
          name="description"
          content="Easily create and edit different types of forms and form layouts using windframe. Create forms visually that you can easily copy to your codebase."
        />
        <meta
          property="og:title"
          content="Windframe Form builder -  Visual form builder for rapidly building stunning forms and form layouts using tailwind css"
        />
        <meta
          property="og:description"
          content="Easily create and edit different types of forms and form layouts using windframe. Create forms visually that you can easily copy to your codebase. "
        />
        <meta
          property="twitter:title"
          content="Windframe Form builder -  Visual form builder for rapidly building stunning forms and form layouts using tailwind css"
        />
        <meta
          property="twitter:description"
          content="Easily create and edit different types of forms and form layouts using windframe. Create forms visually that you can easily copy to your codebase. "
        />
        <link rel="canonical" href="https://windframe.dev/form-builder/" />
        <meta property="og:url" content="https://windframe.dev/form-builder/" />
        <meta property="og:site_name" content="Devwares" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="og:image"
          content="https://devwares-pull-zone.b-cdn.net/windframe-poster-image-1jj.png"
        />
        <meta
          name="twitter:image"
          content="https://devwares-pull-zone.b-cdn.net/windframe-poster-image-1jj.png"
        />
      </Helmet>
      <StyledWrapper>
        <div className="box">
          <div className="">
            <div className="header1">Windframe Form Template</div>
            {/* <a>
              <button className="p-2 btn-primary rounded mb-6" style={{ background: 'red' }}>
                12 Blocks
              </button>
            </a> */}
            <p>
              Easily build different types of forms and form layouts using windframe. Here is one
              template created using the builder. CLick on the edit button to edit this template on
              windframe.
            </p>
          </div>
          <div>
            <PreviewBlock>
              <div className="a">
                <ul>
                  <a href="https://windframe.devwares.com/editor?id=forms13">
                    <li className="tab p-2 btn-primary rounded mb-6" id="preview">
                      Edit Template
                    </li>
                  </a>
                </ul>
              </div>
              <div className="b">
                <div className="active tab" id="preview">
                  <img src={image} alt="blockImage" />
                </div>
              </div>
            </PreviewBlock>
          </div>
        </div>
      </StyledWrapper>
      <Cta />
    </Layout>
  );
};

export default Block;
